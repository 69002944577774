@import "../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

@import "@/assets/style/init.scss";

.common-header-con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    .common-header-left {
        height: 100%;
        display: flex;
        align-items: center;
        .logo {
            width: 80px;
            img {
                width: 100%;
                float: left;
            }
        }
    }
    .navbar-con {
        display: flex;
        align-items: center;
        margin-left: 100px;
        .nav-item {
            margin-right: 60px;
            font-size: 16px;
            cursor: pointer;
            &:hover {
                .txt {
                    color: $primary;
                }
            }
            &:nth-last-child(1) {
                margin-right: 0;
            }
            &.active {
                .txt {
                    color: $primary;
                }
            }
        }
    }
    .common-header-right {
        .common-icon-list {
            display: flex;
            align-items: center;
            .icon-item {
                margin-right: 23px;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:nth-last-child(1) {
                    margin-right: 0;
                }

                .iconfont {
                    color: $font;
                    font-size: 26px;
                }
            }
        }
    }
    .common-search-con {
        display: flex;
        align-items: center;
        padding-right: 20px;
        .icon-item {
            margin-top: 2px;
        }
        .search-popup {
            position: relative;
            display: flex;
            justify-content: flex-start;
            z-index: 2;
            &.hide{
                .search-popup-header {
                    animation: hide_search 0.1s ease-in-out;
                }
                .search-popup-result {
                    animation: hide_search 0.1s ease-in-out;
                }
            }

            .search-popup-header {
                display: flex;
                align-items: center;
                height: 40px;
                line-height: 40px;
                animation: show_search 0.1s ease-in-out;
                overflow: hidden;
                width: 330px;
                .input-con {
                    display: flex;
                    align-items: center;
                    background-color: #f9f9f9;
                    padding: 0 10px;
                    border-radius: 6px;
                    margin-right: 10px;
                    width: 300px;
                    .iconfont {
                        font-size: 20px;
                        color: $font_desc;
                    }
                    .input {
                        width: 270px;
                    }
                }
                .close-icon {
                    cursor: pointer;
                }
            }

            .search-popup-result {
                position: absolute;
                top: 56px;
                background-color: #fff;
                left: 0;
                width: 300px;
                padding: 10px 20px;
                line-height: 30px;
                border-radius: 0 0 4px 4px;
                animation: show_search 0.1s ease-in-out;
                overflow: hidden;
            box-shadow: 3px 6px 10px rgba(0,0,0,0.07);

            //    box-shadow: 5px 0 10px rgba(0,0,0,0.05);
                .tip {
                    font-size: 13px;
                    color: #888;
                }
                .list {
                    .item {
                        line-height: 30px;
                        color: #333;
                        font-size: 13px;
                        cursor: pointer;
                        white-space: nowrap;
                        &:hover {
                            opacity: 0.7;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
   
}
.common-cart-popup-con {
    width: 300px;
    .cart-list{
        padding: 0px 10px;
        max-height: 230px;
        overflow-y: scroll;
        .empty-con{
            padding: 20px 0;
        }
        .cart-item{
            display: flex;
            align-items: center;
            padding: 5px 5px;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom:1px solid #eee;
            cursor: pointer;
            &:nth-child(1) {
                padding-top: 10px;
            }
            &:nth-last-child(1){
                border:none;
            }
            .img-con{
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
            }
            .info{
                width: 160px;
                margin-left: 10px;
                .name{
                    @include text_1;
                    font-size:14px;
                    line-height: 20px;
                    color:$font;
                }
                .quantity{
                    font-size:12px;
                    margin-top: 4px;
                }
                
            }
            .price{
                  margin-left: auto;  
                  font-size:14px;
                  .sub{
                    font-size:10px;
                    margin-right: 2px;
                  }
                  .v{

                  }
            }
        }
    }
    .cart-footer{
        border-top: 1px solid #eee;
        padding-bottom: 10px;
        .cart-count{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 5px;
            .left{
                font-size:14px;
                .mc{
                    color:$primary;
                }
            }
            .right{
                display: flex;
                align-items: center;
                font-size:14px;

                .label {
                    color:#A9B1B9;
                    margin-right: 6px;
                }
                .price{
                    color:$primary;
                    .sub{
                        font-size:10px;
                        margin-right: 2px;
                    }
                    .v {
                        font-size:16px;

                    }
                }

            }
        }
        .primary-button {
            width: 280px;
            margin: 0 auto;
            background: linear-gradient(90deg, #d97b48 0%, $primary 100%);
            border-radius: 24px;
            line-height: 48px;
            color:#fff;
            text-align: center;
            font-size:18px;
            cursor: pointer;
            margin-top: 0px;
            &.dis {
                background: #ccc;
            }
            &:hover{
                opacity: 0.8;
            }
            &:active{
                opacity: 0.7;
            }
        }
    }
}
    .common-category-popup-con {
        position: absolute;
        top: 72px;
        width: 100vw;
        left: 0;
        background-color: #fff;
        border-top: 1px solid #eee;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        // animation: show_category .1s ease-in-out;
        height: 180px;
        z-index: 2;
        &.hide {
            // animation: hide_category .1s ease-in-out;
        }
        .category-list {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            justify-content: flex-start;
            padding: 20px 0;
            .category-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 140px;
                height: 140px;
                margin-right: 50px;
                cursor: pointer;
                &:hover {
                    background-color: #f8f8f8;
                    border-radius: 6px;
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .img {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin-bottom: 15px;
                    img {
                        width: 100%;
                    }
                }
                .txt {
                    font-size: 14px;
                    line-height: 20px;
                    color: $font;
                }
            }
        }
    }
   


.footer-style {
    background: #212223;
    height: 300px;
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    .foot-hot {
        display: flex;
        height: 100px;
        border-bottom:1px solid rgba(255, 255, 255, .1);
        align-items: center;
        justify-content: space-between;
    }
    .foot-flexCenter {
        display: flex;
        align-items: center;
        font-size: 16px;
    }
    .foot-icon {
        position: relative;
        margin-right: 10px;
        width: 48px;
        height: 48px;
        cursor: pointer;
        .foot-icon-image {
            width: 100%;
            height: 100%;
        }
        .foot-hove-icon {
            width: 200px;
            height: 200px;
            display: none;
            position: absolute;
            top: 55px;
            left: -80px;
        }
        &:hover .foot-hove-icon {
            display: block;
        }
    }
    .foot-info {
        display: flex;
        align-items: center;
        .foot-every-info {
            display: flex;
            align-items: center;
            margin-right: 50px;
            font-size: 12px;
            color: #a9b1b9;
            img {
                height: 32px;
            }
            span {
                margin-left: 10px;
            }
        }
    }
}

@keyframes show_category {
    0% {
        // height: 50px;
        opacity: 0.3;
    }
    100% {
        // height: auto;
        opacity: 1;
    }
}

@keyframes hide_category {
    0% {
        // height: auto;
        opacity: 1;
    }
    100% {
        // height: 50px;
        opacity: 0.3;
    }
}
@keyframes show_search {
    0% {
        width: 100px;
        opacity: 0.3;
    }
    100% {
        width: 300px;
        opacity: 1;
    }
}

@keyframes hide_search {
    0% {
        width: 300px;
        opacity: 1;
    }
    100% {
        width: 100px;
        opacity: 0.3;
    }
}

.footer-con {
    color: #fff;
    text-align: center;
}
