@import "../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

.cart-page-container {
  .page-main {
    padding-bottom: 130px;
    .card-item {
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
    }
    .cart-header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      margin-bottom: 10px;
      color: #262626;
      .left {
        font-size: 18px;
      }
      .to-home {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &:active {
          opacity: 0.7;
        }
        .t {
          margin-right: 4px;
          font-size: 14px;
          line-height: 22px;
        }
        .iconfont {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .cart-list {
      .cart-empty {
        padding: 100px 0;
      }
      .list-con {
        .list-head {
          display: flex;
          align-items: center;
          height: 60px;
          padding: 0 30px;
          border-bottom: 1px solid #eee;
          .head-li {
            display: flex;
            align-items: center;
            &.checkbox {
              .txt {
                margin-left: 6px;
              }
            }
            &:nth-child(1) {
              margin-right: 214px;
            }
            &:nth-child(2) {
              margin-right: 216px;
            }
            &:nth-child(3) {
              margin-right: 167px;
            }
            &:nth-child(4) {
              margin-right: 168px;
            }
            &:nth-child(5) {
              margin-right: 121px;
            }
            .txt {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .list-con-ul {
          padding: 0 30px;

          .cart-item-con {
            display: flex;
            align-items: center;
            padding: 30px 0;
            border-bottom: 1px solid #eee;
            &:nth-last-child(1) {
              border: none;
            }
            .img-con {
              width: 100px;
              height: 100px;
              margin-left: 30px;
              overflow: hidden;
            }
            .name-spec {
              margin-left: 30px;
              width: 300px;
              // background-color: red;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              .name {
                font-size: 16px;
                line-height: 24px;
                color: #1a272c;
                margin-bottom: 10px;
              }
              .spec {
                background-color: #f0f0f0;
                line-height: 24px;
                border-radius: 12px;
                padding: 0 12px;
                @include text_1;
                font-size: 12px;
              }
            }
            .stepper-con {
              margin-left: 0px;
            }
            .unit-price {
              margin-left: 60px;
              display: flex;
              align-items: baseline;
              color: #262626;
              width: 150px;
              &.total-price {
                font-weight: bold;
                margin-left: 115px;
              }
              .sub {
                font-size: 12px;
                margin-right: 2px;
              }
              .price {
                font-size: 16px;
              }
            }
            .edit {
              cursor: pointer;
              margin-left: 10px;
              &:hover {
                opacity: 0.8;
              }
              &:active {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    .cart-footer {
      padding: 0 30px;
      margin-top: 10px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .select-all {
        display: flex;
        align-items: center;
        .delete-mutiple {
          margin-left: 40px;
          cursor: pointer;
          &.dis {
            cursor: not-allowed;
            color: #ddd;
          }
          &:hover {
            opacity: 0.8;
          }
          &:active {
            opacity: 0.7;
          }
        }
      }
      .cart-total-con {
        display: flex;
        align-items: center;
        .total-info {
          .total-price {
            display: flex;
            align-items: center;
            .label {
              font-size: 14px;
              color: #262626;
            }
            .price-con {
              display: flex;
              align-items: baseline;
              color: $primary;
              line-height: 27px;
              .sub {
                font-size: 14px;
                margin-right: 4px;
              }
              .price {
                font-weight: bold;
                font-size: 24px;
              }
            }
          }
          .promotion {
            display: flex;
            align-items: center;
            .label {
              color: #b2b2b2;
              font-size: 14px;
            }

            .price-con {
              display: flex;
              align-items: baseline;
              color: #b2b2b2;
              line-height: 20px;
              .sub {
                font-size: 12px;
                margin-right: 4px;
              }
              .price {
                font-size: 16px;
              }
            }
          }
        }
        .submit-btn {
          width: 160px;
          background: linear-gradient(90deg, #d97b48 0%, $primary 100%);
          border-radius: 24px;
          line-height: 48px;
          color: #fff;
          text-align: center;
          font-size: 18px;
          cursor: pointer;
          margin-left: 50px;
          &.dis {
            background: #ccc;
          }
          &:hover {
            opacity: 0.8;
          }
          &:active {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
