@import "../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }


@mixin listCard {

    background-color: #fff;
    border-radius: 10px;
}
.product-list-container{
   padding-top: 10px;
    .list-filter-con{
        @include listCard;
        padding: 14px 40px;
        margin-bottom: 10px;
        .list-filter-item{
            display: flex;
            align-items: flex-start;
            .label{
                width: 80px;
                color:#B2B2B2;
                font-size:14px;
                line-height: 50px;
                margin-right: 36px;
            }
            .list-con{
                display: flex;
                align-items: center;
                line-height: 50px;
                color:$font;
                width: 980px;
                flex-wrap: wrap;
                .filter-txt{
                    width: 110px;
                    margin-right: 20px;
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    &.active {
                        .txt{
                            color:$primary;
                            font-size:14px;
                        }
                    }
                    .txt{
                        cursor: pointer;
                        font-size: 14px;
                        @include text_1;

                        &:hover{
                            opacity: 0.8;
                            font-size: 14px;
                        }
                    }

                }
                
            }
            .filter-expand{
                display: flex;
                align-items: center;
                justify-content:flex-end;
                width: 80px;
                height: 50px;

                .txt{
                    cursor: pointer;
                    font-size: 14px;
                    @include text_1;
                    margin-right: 4px;
                    color:#B2B2B2;
                    &:hover{
                        opacity: 0.8;
                        font-size: 14px;
                    }
                }
                .iconfont {

                    color:#B2B2B2;
                    font-size: 18px;
                }
            }
        }
    }

    .list-sort-con {
        @include listCard;
        padding: 0px 40px;
        height: 60px;
        display: flex;
        align-items: center;
        user-select: none;

        .label{
            width: 80px;
            color:#B2B2B2;
            font-size:14px;
            line-height: 50px;
            margin-right: 36px;
        }
        .list{

            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item{

                width: 110px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                &:nth-last-child(1) {
                    margin-right: 0;
                }
                &.active {
                    .txt{
                        color:$primary;
                        font-size:14px;
                    }
                    &.desc {
                        .arrow-con{
                            .arrow-bottom {
                                border-top-color:$primary;
                            }
                        }
                    }
                    &.asc {
                        .arrow-con{
                            .arrow-top {
                                border-bottom-color:$primary;
                            }
                        }
                    }
                }
                .txt{
                    cursor: pointer;
                    font-size: 14px;
                    @include text_1;

                    &:hover{
                        opacity: 0.8;
                        font-size: 14px;
                    }
                }
                .arrow-con{
                    margin-left: 4px;
                    .arrow-top{
                        width: 0px;
                        height: 0px;
                        border-bottom: 4px solid #ccc;
                        border-left: 4px solid #fff;
                        border-right: 4px solid #fff;
                        border-top: 4px solid #fff;
                    }
                    .arrow-bottom{
                        width: 0px;
                        height: 0px;
                        border-bottom: 4px solid #fff;
                        border-left: 4px solid #fff;
                        border-right: 4px solid #fff;
                        border-top: 4px solid #ccc;
                        margin-top: 3px;
                    }
                }
            }
        }
    }

    .product-list-con{
        min-height: 80vh;
        padding-bottom: 40px;

        .con-center{
            display: flex;
            justify-content: center;
            .loadin-con {
                padding-top: 100px;
            }
        }
        .list-main{
            display: flex;
            align-items: flex-start;
            justify-items: flex-start;
            flex-wrap: wrap;
            padding-top: 12px;
           

        }
        .pagination-con{
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
    
}