@font-face {
  font-family: "iconfont"; /* Project id 4300876 */
  src: url('iconfont.woff2?t=1698802963625') format('woff2'),
       url('iconfont.woff?t=1698802963625') format('woff'),
       url('iconfont.ttf?t=1698802963625') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xuanze:before {
  content: "\e627";
}

.icon-xuanze1:before {
  content: "\e67c";
}

.icon-add-quantity:before {
  content: "\e622";
}

.icon-reduce-1:before {
  content: "\eb1b";
}

.icon-xuanzhong:before {
  content: "\e626";
}

.icon-close2:before {
  content: "\e6dd";
}

.icon-gerenxinxi:before {
  content: "\e60e";
}

.icon-dingdan:before {
  content: "\e645";
}

.icon-tuichu:before {
  content: "\e623";
}

.icon-zhanghaoxinxi:before {
  content: "\e64c";
}

.icon-arrow-left-copy:before {
  content: "\e8f4";
}

.icon-gouwuche:before {
  content: "\e63f";
}

.icon-arrow-right:before {
  content: "\e8f1";
}

.icon-wode-copy:before {
  content: "\e64a";
}

.icon-sousuo:before {
  content: "\e600";
}

.icon-arrow-top:before {
  content: "\e8f2";
}

.icon-arrow-bottom-copy:before {
  content: "\e8f3";
}

