@import "../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

.product-detail-container {
  min-height: 100vh;
  background-color: #fff;
  .empty-con {
    padding-top: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading-con {
    padding-top: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-detail-main {
    .product-detail-info {
      display: flex;
      align-items: flex-start;
      padding-bottom: 53px;
      // justify-content: space-between;
      .detail-carousel {
        // background-color: #f5f5f5;
        width: 520px;
        min-height: 520px;
        margin-right: 60px;
        .carousel-con {
          margin-bottom: 10px;
          .carousel-item {
            width: 520px;
            height: 520px;

            border-radius:4px;
            overflow: hidden;
            .img-con {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 520px;
              height: 520px;
              margin: 0 auto;
              //   margin-top: 35px;
              img {
                width: 100%;
              }
            }
          }
        }
        .dots-con {
          width: 520px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .arrow-icon {
            width: 35px;
            height: 100px;
            box-sizing: border-box;

            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #eeeeee;
            cursor: pointer;
            &:hover{
              opacity: 0.7;
            }
            &:active{
              opacity: 0.6;
            }
            .iconfont {
              color: #b2b2b2;
              font-weight: bold;
              font-size: 20px;
            }
          }
          .carousel-dots-list {
            width: 430px;
            overflow-x: scroll;
            .list-con {
              display: flex;
              align-items: flex-start;
              flex-wrap: nowrap;
              flex: 1;
            }
            .carousel-dots-item {
              width: 100px;
              height: 100px;
              flex-shrink: 0;

              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              margin-right: 10px;
              cursor: pointer;
              border: 1px solid transparent;
              border-radius:4px;
              overflow: hidden;
              &.active {
                border-color:$primary;
              }
              &:hover{
                opacity: 0.7;
              }
              &:active{
                opacity: 0.6;
              }
              &:nth-last-child(1) {
                margin-right: 0;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
      .detail-info {
        // background-color: #f5f5f5;
        width: 620px;
        &-name{
            font-size:24px;
            line-height: 35px;
            color:$font;
            // font-weight: bold;
            padding-bottom: 30px;
            border-bottom:1px solid #eee;
        }
        &-list{
            padding-top: 44px;
            .info-item{
                margin-bottom: 20px;
                display: flex;
                align-items: flex-start;
                .label {
                    font-size:14px;
                    line-height: 22px;
                    width: 80px;
                    line-height: 40px;
                }
                .value {
                    min-height: 40px;
                    line-height: 40px;
                    font-size:14px;
                    &.price-con{
                        display: flex;
                        align-items: baseline;
                        color:$primary;
                        margin-top: -8px;
                        .sub{
                            font-size:18px;

                        }
                        .price{
                            font-size:36px;
                            margin-left: 4px;
                            font-weight: bold;
                        }
                        .market{
                            text-decoration: line-through;
                            font-size:18px;
                            margin-left: 10px;
                            color:#B2B2B2;
                        }
                    }
                    &.service-list{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        .service-item{
                            display: flex;
                            align-items: center;
                            margin-right: 30px;
                            line-height: 40px;
                            .iconfont {
                                color:$primary;
                                margin-right: 4px;
                                font-size:25px;
                            }
                        }
                    }
                    &.freight{
                        font-size:18px;
                        display: flex;
                        align-items: baseline;
                        color:$primary;
                        margin-top: -3px;
                        .sub{
                            font-size:14px;
                            margin-right: 4px;

                        }
                        .price{
                            font-size:23px;
                        }
                        .promotion {
                          font-size: 13px;
                        }
                    }
                    &.spec-list{
                        display: flex;
                        flex-wrap: wrap;
                        .spec-item{
                            line-height: 38px;
                            background: #F0F0F0;
                            border-radius: 24px;
                            opacity: 1;
                            padding: 0 14px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            cursor: pointer;
                            border:1px solid transparent;
                            display: flex;
                            align-items: center;
                            &.active{
                                background-color: #fff;
                                border-color:$primary
                            }
                            &.disabled{
                              color:#ccc;
                              cursor: not-allowed;
                              .img{
                                opacity: 0.6;
                              }
                            }
                            .img{
                              width: 30px;
                              height: 30px;
                              overflow: hidden;
                              border-radius:0px;

                              display: flex;
                              align-items: center;
                              justify-content: center;
                              margin-right: 4px;
                              img{
                                width: 100%;
                              }
                              
                            }
                        }
                    }
                    &.stepper{
                        display: flex;
                        align-items: center;
                    }
                    .primary-button {
                        width: 160px;
                        background: linear-gradient(90deg, #d97b48 0%, $primary 100%);
                        border-radius: 24px;
                        line-height: 48px;
                        color:#fff;
                        text-align: center;
                        font-size:18px;
                        cursor: pointer;
                        margin-top: 20px;
                        &.dis {
                            background: #ccc;
                        }
                        &:hover{
                            opacity: 0.8;
                        }
                        &:active{
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

      }
    }

    .product-description {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 60vh;
        .tab-con{
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            .tab-item{
                line-height: 62px;
                padding:0 30px;
                font-size:18px;
                position:relative;
                color:#1A272C;
                &.active{
                    color:$primary;
                    &::after{
                        content:'';
                        @include x_c;
                        bottom:0;
                        height: 2px;
                        width: 40px;
                        background-color: $primary;
                    }
                }
            }
        }
        .detail-content{
            width: 790px;
            padding-bottom: 100px;

            .description-detail {
                img{
                    float: left;
                }
            }

        }
        .recommend-content{
            width: 360px;
        }
    }
  }
}
