@import "../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

.common-product-card {
    width: 291px;
    height: 380px;
    background-color: #fff;
    margin-right: 12px;
    margin-bottom: 12px;
    padding-top: 30px;
    box-sizing: border-box;
    // box-shadow: 0px 10px 20px 0px rgba(169,177,185,0.15);
    border-radius:10px;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 10px 20px 0px rgba(169,177,185,0.15);
        .product-img-con {
            .image{
                // transform: scale(1.1);
            }
        }
        .product-info-con {
            .detail-arraw{
                opacity: 1;
            }
        }
    }
    &:active {
        // background-color: #f9f9f9;
        opacity: 0.7;
        .product-img-con {
            // .image{
            //     transform: scale(1.1);
            // }
        }
    }
    &:nth-child(4n) {
        margin-right: 0;
    }
    .product-img-con {
        width: 200px;
        height: 200px;
        overflow: hidden;
        display: flex;
        margin: 0 auto;
        margin-bottom: 20px;
        .image{
            width: 100%;
            transition:all .1s ease-in-out;
        }
    }
    .product-info-con{
        padding: 0 30px;
        position:relative;
        .name{
            font-size:16px;
            color:$font;
            line-height: 24px;
            @include text_1;
            margin-bottom: 4px;
        }
        .desc{
            color: #A9B1B9;
            line-height: 20px;
            font-size:14px;

        }
        .price-con{
            margin-top: 20px;
            display: flex;
            align-items: baseline;
            .default{
                display: flex;
                align-items: baseline;
                margin-right: 8px;
                color:$font;
                .sub{
                    font-size:12px;
                    line-height: 50px;
                    margin-right: 4px;
                }
                .price{
                    font-size:24px;
                    line-height: 40px;
                    font-weight: bold;
                }
            }
            .market{
                color:#A9B1B9;
                font-size:14px;
                text-decoration: line-through;

            }
        }
        .detail-arraw {
            width: 40px;
            height: 40px;
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            position:absolute;
            right: 20px;
            bottom:10px;
            border-radius:50%;
            opacity: 0;
            transition:all .3s ease-in-out;
            .iconfont{
                color:#fff;
                font-size:22px;

            }
        }
    }
}