@import "../../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

@mixin border {
    background: #FFFFFF;
    border-radius: 10px;
}

.header {
    display: flex;
    height: 60px;
    @include border;
    align-items: center;
    padding-bottom: 2px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;

    &_text {
        margin: 0 30px;
        cursor: pointer;
        position: relative;
        height: 100%;
        line-height: 60px;
    }

    .active {
        color: $primary;

        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            display: block;
            background: $primary;
        }
    }
}

.empty {
    text-align: center;
    min-height: 560px;
    @include border;
    padding-top: 100px;

    img {
        width: 200px;
    }

    div {
        width: 120px;
        height: 36px;
        background: $primary;
        margin: 0 auto;
        line-height: 36px;
        border-radius: 20px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

    }
}

