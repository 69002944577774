@import "../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

.about-container{
  padding: 0 20px;
  .page-main{

    // padding-top: 80px;
    padding-bottom: 100px;
  
    .card-item{
      padding: 0 30px;
      border-radius:16px;
      overflow: hidden;
      background-color: #fff;
      margin-bottom: 12px;
      .card-title{
        line-height: 60px;
        color:#333333;
        font-size:18px;
        font-weight: bold;
        border-bottom:1px solid #eee;
      }
      .card-content{
        padding: 20px 0;
        &.video-list {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 40px;
          .video-item {
            width: 240px;
            cursor: pointer;
            &:hover{
              .img{
                .play-icon {
                  .icon{
                    transform: scale(1.4);
                  }
                }
              }
            }
            .img{
              height: 135px;
              overflow: hidden;
              border-radius: 6px;
              position: relative;
              img{
                max-width: 100%;
              }
              .play-icon{
                @include c;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                .icon{
                  color:#fff;
                  font-size:26px;
                }
              }
            }
            .info{
              padding-top: 10px;
              padding-left: 6px;
              .title{
                font-size:16px;
                font-weight: bold;
                margin-bottom: 4px;
                color:#000;
                line-height: 24px;
              }
              .desc{
                font-size:14px;
                color:#666;
                line-height: 24px;
              }
            }
          }
        }
        &.about {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 40px;
          .about-info{
            width: 520px;
            padding-top: 10px;
            .title{
              font-size:30px;
              margin-bottom: 20px;
            }
            .txt{
              font-size:14px;
              line-height: 23px;
              margin-bottom: 20px;
            }
          }
          .img{
            width: 520px;
            border-radius:10px;
            overflow: hidden;
          }
        }
      }
    }
   
  }
 
  
}
.video-popup {
  .ant-modal-content{

  border-radius:10px;
  overflow: hidden;
    padding: 0;
    // width: 800px;
    // height: 450px;
    background-color: #000;
    video{
      float: left;
    }
  }
}