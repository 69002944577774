@import "../../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

._item {
    min-height: 130px;
    border-radius: 10px;
    border: 1px solid #EEEEEE;
    padding: 20px 20px 15px;
    margin-bottom: 20px;

    line-height: 1.2;

    &.add {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        p {
            margin-left: 5px;
        }
    }

    &_user {
        font-weight: 500;
        color: #262626;

        span:first-child {
            font-size: 14px;
        }

        span+span {
            font-size: 12px;
            margin-left: 10px;
        }

    }

    &_det {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 22px;
        line-height: 22px;

        * {
            display: inline-block;
            vertical-align: middle;
        }
        span {
            width: 36px;
            height: 22px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid rgba(236, 109, 42, 0.3);
            font-size: 10px;
            font-weight: 400;
            color: $primary;
            text-align: center;
            margin-right: 5px;
        }
        p{
            @include text_1;
            font-size: 14px;
            font-weight: 400;
            color: #6E6E6E;
            width: calc(100% - 41px);
        }
    }

    &_btn {
        font-size: 14px;
        font-weight: 400;
        color: #B2B2B2;
        padding: 0;
        margin-right: 20px;
        &:hover{
            background-color:transparent !important;
        }
    }
}