@import "../../../assets/font/iconfont.scss";


// 页面版心宽度
$pageWrapperWidth: 1200px;

$primary: #EC6D2A; // 主色
$font: #1A272C;
$font_desc: #B2B2B2;

body,html {
    padding: 0;
    margin: 0;
    color: $font;
}
*{
    padding: 0;
    margin: 0; 
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.page-wrapper {
    width: $pageWrapperWidth;
    margin: 0 auto;
}

.page-holder {
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-weight: bold;
    font-size:23px;
    margin: 200px auto;
    background-color: rgba(0,0,0,0.6);
    color:#fff;
    border-radius: 50%;
}



@mixin text_1 {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  
  @mixin text_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
  }
  
  @mixin y_c {
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }
  
  @mixin x_c {
    left: 50%;
    transform: translate(-50%, 0%);
    position: absolute;
  }
  
  @mixin c {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

// table 2
@mixin table2{
    width: 100px;
    min-width: 100px;
    margin: 0 50px 0 80px;
}
@mixin table3{
    width: 180px;
    min-width: 180px;
    margin-right: 0;
    text-align: center;
}

._item {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #262626;

    &_header {
        height: 62px;
        display: flex;
        border-bottom: 1px solid #EEEEEE;
        align-items: center;
        padding: 0 30px;
       
        line-height: 22px;
        &_time {
            font-size: 12px;
            color: #6E6E6E;
            line-height: 22px;
            margin-left: 60px;
        }

        &_status {
            color: $primary;
            margin-left: auto;
        }
    }

    &_content {
        padding: 30px;
        display: flex;
        align-items: center;
      

        &_goods {
            width: 340px;
            min-width: 340px;
            display: flex;
            align-items: center;

            img {
                width: 100px;
                height: 100px;
                margin-right: 10px;
            }

            &_text {
                line-height: 1.2;
                max-width: 190px;
                font-size: 16px;
                font-weight: 400;
                color: #1A272C;
                margin-left: 20px;

                p {
                    font-size: 12px;
                    color: #ccc;
                    margin-top: 10px;
                }
            }
        }

        &_count {
            min-width: 100px;
            text-align: center;
            margin: 0 120px;
        }

        &_payPrice {
            min-width: 200px;
            font-size: 16px;
            font-weight: 700;
            margin-right: auto;
        }

        &_operate {
            cursor: pointer;
            color: #6E6E6E;
        }
    }
}

// 物流
._logistics {
}
// 详情
._detail {
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    &_top{
        background: #fff;
        // 商品详情样式覆盖
        ._item_content{
            border-bottom: 1px solid #EEEEEE;
            &_count {
                @include table2;
            }
            &_payPrice {
                @include table3;
            }
            &_operate {
                cursor: none;
                @include table3;
                color: #262626;
            }
        }
    }
    
    &_table{
        display: flex;
        align-items: center;
        height: 60px;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        text-align: center;
        padding: 0 30px;
       
        div:first-child{
            width: 340px;
        }
        div:nth-child(2){
           @include table2;
        }
        div:nth-child(n+3){
            @include table3;
        }
    }
    &_bottom{
        background: #fff;
        display: flex;
        padding: 30px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      
        color: #6E6E6E;
    
        div {
            display: flex;
            align-items: center;
            width: 50%;
            margin-top: 10px;
    
            &:nth-child(2n) {
                justify-content: flex-end;
            }
    
            &>span {
                min-width: 70px;
                width: 70px;
                margin-right: 30px;
            }
    
            &>p {
                color: #262626;
                min-width: 100px;
               
            }
        }
        div._detail_total{
            margin-top: 18px;
            width: 100%;
            justify-content: flex-end;
            p{
                font-size: 16px;
                font-weight: 700;
                color: $primary;
            }
        }
    }

    
}